import axios from "axios";
import {
  FORGOT_USER_PASSWORD_URL,
  POST_REGISTER_URL,
  RESET_USER_PASSWORD_URL,
  url,
} from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";
import {
  POST_FORGET_PASSWORD,
  POST_RESET_PASSWORD,
  REGISTER_USER,
} from "./type";
import swal from "sweetalert";

/*-------------------------------------------- */
export const SignIn = (email, password) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    await axios
      .post(`${url}/login`, { email, password })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("user_id", res.data.user.id);
        localStorage.setItem("name", res.data.user.name);
        localStorage.setItem(
          "title",
          res.data.user.roles.map((role) => role.title)
        );
        localStorage.setItem("role_id", res.data.user.roles[0].id);
        localStorage.setItem("salesAgent", JSON.stringify(res.data.user));
        dispatch({
          type: "SIGN_IN",
          token: res.data.access_token,
          parent_id: res.data.user.parent_id,
          user_id: res.data.user_id,
          name: res.data.user.name,
          address: res.data.user.address,
          email: res.data.user.email,
          phone: res.data.user.phone,
          mantras: res.data.user.mantras,
          image_path: res.data.user.image_path,
          title: res.data.user.roles.map((role) => role.title),
        });
        dispatch(HideLoading());
      })
      .catch(async (err) => {
        await dispatch(HideLoading());
        await swal({
          title: "Email or Password is incorrect",
          // text: "Once deleted, you will not be able to recover this imaginary file!",
          icon: "warning",
          button: true,
          dangerMode: true,
        });
      });
  };
};

/*-------------------------------------------- */
export const SignOut = () => {
  return (dispatch) => {
    dispatch({
      type: "SIGN_OUT",
    });
  };
};

/*-------------------------------------------- */
export const loadUser = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    const title = localStorage.getItem("title");
    const user_id = localStorage.getItem("user_id");
    const role_id = localStorage.getItem("role_id");

    if (token) {
      dispatch({
        type: "USER_LOADED",
        token,
        user_id,
        name,
        title,
        role_id,
      });
    } else return null;
  };
};
/*-------------------------------------------- */

export const forgotUserPasAction = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${FORGOT_USER_PASSWORD_URL}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: POST_FORGET_PASSWORD,
          userPassword: res.data,
        });
        dispatch(HideLoading());
        if (res.status === 200) {
          swal({
            title: "Password reset request sent!",
            text: "Check your Email!",
            icon: "success",
            button: "OK",
          });
        }
      })
      .catch((err) => {
        dispatch(HideLoading());
        console.log(err.data);
      });
  };
};

/*-------------------------------------------- */

export const resetUserPasAction = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${RESET_USER_PASSWORD_URL}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: POST_RESET_PASSWORD,
          resetPassword: res.data,
        });
        dispatch(HideLoading());
        if (res.status === 200) {
          swal({
            title: "Password reset succsfully ",
            text: "Go To Login Now",
            icon: "success",
            button: "OK",
          });
        }
      })
      .catch((err) => {
        dispatch(HideLoading());
        swal({
          title: " wrong ",
          text: "Somthing went wrong ",
          icon: "error",
          button: "OK",
        });
      });
  };
};

export const CreateNewUser = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${POST_REGISTER_URL}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: REGISTER_USER,
          payload: {
            registerUser: res.data,
          },
        });

        if (res.status === 200) {
          swal({
            title: "Register User succsfully ",
            text: "Register User succsfully ",
            icon: "success",
            button: "OK",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
