import {
  Button,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Calculate } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalcoulateActions,
  // loadAprCalc,
  loadSunLightCalc,
  officalCalc,
  TotalAmount,
} from "../../Redux/Actions/CalculatorActions";
import styled from "styled-components";
import img from "../../assets/sunlightLogo.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "1rem",
  p: 4,
};

// const termsInYears = [
//   { id: 5, value: "5 Years " },
//   { id: 10, value: "10 Years " },
//   { id: 12, value: "12 Years " },
//   { id: 15, value: "15 Years " },
//   { id: 20, value: "20 Years " },
//   { id: 25, value: "25 Years " },
// ];

const Calc = () => {
  /*--------------- start a state --------- */
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { singlead } = useSelector((state) => state.leads);
  const StatName = singlead.state?.name;

  const handleOpen = () => {
    setOpen(true);
    // dispatch(loadSunLightCalc());
  };

  const [data, setData] = useState({
    termId: "",
    term: "",
    state_name: "",
    loanAmount: "",
    paydownPercentage: "30",
    apr: "",
    alternatePaydown: "",
    dealer_fee: "",
    deposit: "",
  });

  const { sunlightCalc, isCalc, totalAmount } = useSelector(
    (state) => state.calc
  );

  console.log("sunlightCalcsunlightCalcsunlightCalc", sunlightCalc);

  const TermID = data.termId;
  const singleTerm = !isCalc
    ? sunlightCalc?.find((item) => item.id == TermID)
    : null;

  //console.log(FindTheTerm, "falsee");
  /************************************************ */
  const handleChangeCalc = () => {
    dispatch(officalCalc({ ...data }));
  };
  useEffect(() => {
    dispatch(officalCalc({ ...data }));
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(CalcoulateActions({ ...data }));
    dispatch(officalCalc({ ...data }));
    handleClose();
  };

  console.log("Delaer feee", data);

  return (
    <>
      {!isCalc && (
        <div>
          <Button
            onClick={handleOpen}
            startIcon={<Calculate />}
            variant="contained"
            fullWidth
          >
            Sunlight Financial Calculator
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form>
                <div style={{ position: "relative" }}>
                  <h2>Sunlight Financial Calculator </h2>
                  <Img src={img}></Img>
                </div>
                <br />
                {/* <Grid item container spacing={2} columns={18}>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Cash Deposit"
                        value={data.deposit}
                        onChange={async (e) => {
                          await setData({
                            ...data,
                            deposit: Number(e.target.value),
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth className="formControl">
                      <InputLabel id="demo-simple-select-label">
                        Term in Year
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        required
                        value={data.termId}
                        label="Term in Years "
                        onChange={async (e) => {
                          setData({ ...data, termId: e.target.value });
                          await handleChangeCalc();
                        }}
                      >
                        {sunlightCalc?.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Loan Amount"
                        value={(data.loanAmount = Math.ceil(totalAmount) || "")}
                        onChange={(e) =>
                          setData({
                            ...data,
                            loanAmount: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    sx={{ display: "none" }}
                  >
                    <TextField
                      className="formControl"
                      fullWidth
                      label="Term in Month"
                      disabled={true}
                      value={(data.term = singleTerm?.term) || ""}
                      onChange={(e) =>
                        setData({ ...data, term: e.target.value }) || false
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    sx={{ display: "none" }}
                  >
                    <TextField
                      label="APR"
                      className="formControl"
                      fullWidth
                      disabled={true}
                      value={(data.apr = singleTerm?.apr) || ""}
                      onChange={(e) =>
                        setData({ ...data, apr: e.target.value }) || false
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    style={{ display: "none" }}
                  >
                    <TextField
                      label="Dealar Fees"
                      className="formControl"
                      fullWidth
                      disabled={true}
                      value={(data.dealer_fee = singleTerm?.dealer_fee || "")}
                      onChange={(e) =>
                        setData({ ...data, dealer_fee: e.target.value }) ||
                        false
                      }
                    />
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="State Name"
                        disabled={true}
                        value={(data.state_name = StatName) || ""}
                        onChange={(e) =>
                          setData({ ...data, state_name: e.target.value }) ||
                          false
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        disabled
                        label="Voluntary Payment Percentage"
                        step="0.01"
                        value={data.paydownPercentage}
                        onChange={(e) =>
                          setData({
                            ...data,
                            paydownPercentage: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    container
                    xs={18}
                    item
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonStyled
                      onClick={handleSubmit}
                      fullWidth
                      type="submit"
                      className="btnCalc"
                      endIcon={<Calculate />}
                      variant="contained"
                    >
                      Calculate
                    </ButtonStyled>
                  </Grid>
                </Grid> */}
                <Grid item container spacing={2} columns={18}>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Term"
                        // value={data.deposit}
                        onChange={async (e) => {
                          await setData({
                            ...data,
                            term: Number(e.target.value),
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth className="formControl">
                      {/* <InputLabel id="demo-simple-select-label">
                        Term in Year
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        required
                        value={data.termId}
                        label="Term in Years "
                        onChange={async (e) => {
                          setData({ ...data, termId: e.target.value });
                          await handleChangeCalc();
                        }}
                      >
                        {sunlightCalc?.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <TextField
                        label="Interest rate"
                        // value={data.deposit}
                        onChange={async (e) => {
                          setData({ ...data, interest_rate: e.target.value });
                          await handleChangeCalc();
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Loan Amount"
                        value={(data.loanAmount = Math.ceil(totalAmount) || "")}
                        onChange={(e) =>
                          setData({
                            ...data,
                            loanAmount: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    // sx={{ display: "none" }}
                  >
                    <TextField
                      className="formControl"
                      fullWidth
                      label="dealer fee"
                      disabled={true}
                      // value={(data.term = singleTerm?.term) || ""}
                      onChange={(e) =>
                        setData({ ...data, dealer_fee: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    // sx={{ display: "none" }}
                  >
                    <TextField
                      label="First payment"
                      className="formControl"
                      fullWidth
                      // disabled={true}
                      // value={(data.apr = singleTerm?.apr) || ""}
                      onChange={(e) =>
                        setData({ ...data, first_payment: e.target.value }) ||
                        false
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    // style={{ display: "none" }}
                  >
                    <TextField
                      label="Second payment"
                      className="formControl"
                      fullWidth
                      // disabled={true}
                      // value={(data.dealer_fee = singleTerm?.dealer_fee || "")}
                      onChange={(e) =>
                        setData({ ...data, second_payment: e.target.value }) ||
                        false
                      }
                    />
                  </Grid>

                  {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="State Name"
                        disabled={true}
                        value={(data.state_name = StatName) || ""}
                        onChange={(e) =>
                          setData({ ...data, state_name: e.target.value }) ||
                          false
                        }
                      />
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        disabled
                        label="Voluntary Payment Percentage"
                        step="0.01"
                        value={data.paydownPercentage}
                        onChange={(e) =>
                          setData({
                            ...data,
                            paydownPercentage: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        label="Monthly payment for 1-17"
                        step="0.01"
                        value={data.monthlyPayment}
                        onChange={(e) =>
                          setData({
                            ...data,
                            monthlyPayment: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        label="Monthly payment for 18+"
                        value={data.finalEscalatedMonthlyPayment}
                        onChange={(e) =>
                          setData({
                            ...data,
                            finalEscalatedMonthlyPayment: Number(
                              e.target.value
                            ),
                          })
                        }
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid
                    container
                    xs={18}
                    item
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonStyled
                      onClick={handleSubmit}
                      fullWidth
                      type="submit"
                      className="btnCalc"
                      endIcon={<Calculate />}
                      variant="contained"
                    >
                      Calculate
                    </ButtonStyled>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

const ButtonStyled = styled(Button)`
  background: #ff9800;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  width: 250px;
  margin: auto;
`;
const Img = styled.img`
  position: absolute;
  width: 5%;
  top: 0;
  right: 0;
`;
export default Calc;
