import React from "react";
import FormContainer from "./components/form/index";
import { BigSection } from "./style";
import NewHeader from "../NewLandingPage/LandingPageComponents/Header/NewHeader";
const SalesRegister = () => {
  return (
    <BigSection>
      <div className="container">
        <FormContainer />
      </div>
    </BigSection>
  );
};

export default SalesRegister;
