import CustomerCard from "./LeadCard/LeadCard";
import LeadFilter from "./LeadFilter/LeadFilter";

import { LeadStyled } from "./LeadsStyle";
import AddLead from "./AddLead/AddLead";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadActions,
  getLeadsSkipAndTakes,
} from "../../../../Redux/Actions/leadActions";
import { GetSingleUser } from "../../../../Redux/Actions/EditUserAction";
import ReactPaginate from "react-paginate";
import { Button, Skeleton } from "@mui/material";
import axios from "axios";
import { MAIN_URL } from "../../../../API/Api";
import { getLeadsFiltered } from "../../../../Redux/Actions/leadFilterAction.jsx";

const Leads = () => {
  /********************************** */
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const { user_id } = useSelector((state) => state.auth);

  // React.useEffect(()=>{
  //   dispatch(getLeadsFiltered("ahmed"))
  // },[])

  const filteredLeads = useSelector(
    (state) => state.FilteredLeads.allFilteredLeads
  );
  const String = useSelector((state) => state.leads.stringSearch);
  const toggle = useSelector((state) => state.leads.toggleSearch);
  let toogleSearch;
  if (toggle) {
    toogleSearch = "1";
  } else {
    toogleSearch = "0";
  }
  // console.log("FilteredLeads in Leads Page",toggle)
  // console.log("FilteredLeads in Leads Page",toogleSearch)
  useEffect(() => {
    dispatch(GetSingleUser(user_id));
  }, [dispatch]);

  // For Filteration by search
  React.useEffect(() => {
    dispatch(getLeadActions(String, toogleSearch));
  }, []);
  React.useEffect(() => {
    if (toggle) {
      dispatch(getLeadActions(String, toogleSearch));
    }
  }, [toggle]);
  console.log("String whwat", String);
  React.useLayoutEffect(() => {
    if (String || String === "") {
      console.log("Happenddddddddd", String);

      const delayDebounceFn = setTimeout(() => {
        dispatch(getLeadActions(String, toogleSearch));
      }, 550);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [String]);
  // keyWords for search
  // const[keyWord,setKeyWord]=React.useState("")

  const { leadlist, isLeadLoading } = useSelector((state) => state.leads);

  /// start a paginate function

  //FOR PAGE NUMBER
  const [pageNumber, setPageNumber] = useState(0);
  //FOR USER PER PAGE
  const userPerPage = 11;
  //PAGES VISITED
  const pagesVisited = pageNumber * userPerPage;
  //PAGE COUNTER
  //const get the Lenth
  const LengthLeads = leadlist?.length + leads?.length;
  const pageCount = Math.ceil(LengthLeads / userPerPage);

  //ACTION WHEN CLICK NUMBER TO CHANGE DATA
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
  const getLeadsSkip = async (skip, take) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${MAIN_URL}/api/v1/leads/skip/${skip}/take/${take}`,
      headers: headers,
    })
      .then((res) => {
        setLeads([...leads, ...res.data.data]);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  // getLeadsSkip(LengthLeads);

  // console.log(leads, "leads");

  //.slice(pagesVisited, pagesVisited + userPerPage)
  //GET USER ARRAY WITH USER PAGE NUMBER
  console.log("leadlist", leadlist);
  const displayLeads = leadlist?.map((lead) => (
    <CustomerCard
      key={lead.id}
      phone={lead.mobile}
      E_mail={lead.email}
      id={lead.id}
      name={lead.first_name}
      lastName={lead.last_name}
      address={lead.location}
      time={lead.time}
      days={lead.days}
      price={lead.price}
      services={lead.service_types}
      opportunities={lead.opportunities}
      street={lead.street}
    />
  ));
  const SkipLeads = leads?.map((lead) => (
    <CustomerCard
      key={lead.id}
      phone={lead.mobile}
      E_mail={lead.email}
      id={lead.id}
      name={lead.first_name}
      lastName={lead.last_name}
      address={lead.location}
      time={lead.time}
      days={lead.days}
      price={lead.price}
      services={lead.service_types}
      opportunities={lead.opportunities}
      street={lead.street}
    />
  ));

  return (
    <>
      <div className="customer">
        <LeadFilter />

        <LeadStyled>
          <div className="grid">
            {!isLeadLoading && (
              <>
                <AddLead />
                {displayLeads}
                {SkipLeads}
                <Button
                  className="btn_see_more"
                  onClick={() => getLeadsSkip(LengthLeads, 7)}
                >
                  See More
                </Button>
              </>
            )}
            {isLeadLoading && (
              <>
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
              </>
            )}
          </div>
          {/* <ReactPaginate
            nextLabel="Next >"
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"paginateContainer"}
            previousClassName={"prevPaginate"}
            nextClassName={"nextPaginate"}
            disabledClassName={"disabledPaginate"}
            activeClassName={"activePaginate"}
            pageLinkClassName={"pageLinkClassName"}
            pageClassName={"pageClassName"}
            pageRangeDisplayed={5}
          /> */}
          {/* <div className="btn_conatiner">
            <Button
              className="btn"
              onClick={() => dispatch(getLeadsSkipAndTakes(LengthLeads, 8))}
            >
              See More
            </Button>
          </div> */}
        </LeadStyled>
      </div>
    </>
  );
};

export default Leads;
