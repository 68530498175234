import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";
export const salesForm = createApi({
  reducerPath: "salesForm",
  baseQuery,
  endpoints: (builder) => ({
    getSalesCompanies: builder.query({
      query: ({ page, return_all }) => ({
        url: "sales-rep/businesses",
        method: "get",
      }),
    }),

    createSalesAccount: builder.mutation({
      query: (body) => ({
        url: "sales-rep/register",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetSalesCompaniesQuery, useCreateSalesAccountMutation } =
  salesForm;
