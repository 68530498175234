import CustomerCard from "./LeadCard/LeadCard";
import LeadFilter from "./LeadFilter/LeadFilter";

import { LeadStyled } from "./LeadsStyle";
import AddLead from "./AddLead/AddLead";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetArchivedLeads,
  getLeadActions,
  getLeadsSkipAndTakes,
} from "../../../../Redux/Actions/leadActions";
import { GetSingleUser } from "../../../../Redux/Actions/EditUserAction";
import ReactPaginate from "react-paginate";
import { Button, Skeleton } from "@mui/material";
import axios from "axios";
import { MAIN_URL } from "../../../../API/Api";

const ArchiveLeads = () => {
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const { user_id } = useSelector((state) => state.auth);
  const Archived = "archive";
  useEffect(() => {
    dispatch(GetArchivedLeads(user_id, Archived));
  }, []);

  const { leadlist, isLeadLoading, archiveLeads } = useSelector(
    (state) => state.leads
  );

  /// start a paginate function

  //FOR PAGE NUMBER
  const [pageNumber, setPageNumber] = useState(0);
  //FOR USER PER PAGE
  const userPerPage = 11;
  //PAGES VISITED
  const pagesVisited = pageNumber * userPerPage;
  //PAGE COUNTER
  //const get the Lenth
  const LengthLeads = archiveLeads.length + leads.length;
  const pageCount = Math.ceil(LengthLeads / userPerPage);

  //ACTION WHEN CLICK NUMBER TO CHANGE DATA
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
  const getLeadsSkip = async (skip, take) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${MAIN_URL}/api/v1/leads/skip/${skip}/take/${take}`,
      headers: headers,
    })
      .then((res) => {
        setLeads([...leads, ...res.data.data]);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  //GET USER ARRAY WITH USER PAGE NUMBER
  const displayLeads = archiveLeads.map((lead) => (
    <CustomerCard
      key={lead.id}
      phone={lead.mobile}
      E_mail={lead.email}
      id={lead.id}
      name={lead.first_name}
      lastName={lead.last_name}
      address={lead.location}
      time={lead.time}
      days={lead.days}
      price={lead.price}
      services={lead.service_types}
      opportunities={lead.opportunities}
      street={lead.street}
    />
  ));
  const SkipLeads = archiveLeads?.map((lead) => (
    <CustomerCard
      key={lead.id}
      phone={lead.mobile}
      E_mail={lead.email}
      id={lead.id}
      name={lead.first_name}
      lastName={lead.last_name}
      address={lead.location}
      time={lead.time}
      days={lead.days}
      price={lead.price}
      services={lead.service_types}
      opportunities={lead.opportunities}
      street={lead.street}
    />
  ));

  return (
    <>
      <div className="customer">
        <LeadFilter />

        <LeadStyled>
          <div className="grid">
            {!isLeadLoading && (
              <>
                <AddLead />
                {displayLeads}
                {/* {SkipLeads} */}
                {/* <Button
                  className="btn_see_more"
                  onClick={() => getLeadsSkip(LengthLeads, 7)}
                >
                  See More
                </Button> */}
              </>
            )}
            {isLeadLoading && (
              <>
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
                <Skeleton height={300} width={300} variant="rectangular" />
              </>
            )}
          </div>
        </LeadStyled>
      </div>
    </>
  );
};

export default ArchiveLeads;
