import styled from "styled-components";

export const BigSection = styled.div`
  box-shadow: rgba(0, 0, 0, 0.07) 0px 5px 15px;
  padding: 20px;
  .container {
    background: #f5f5f5;
    width: 100%;
    border-radius: 1.5rem;
  }
`;
