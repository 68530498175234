import {
  Business,
  LocationOn,
  Mail,
  ModeEdit,
  Paid,
  PhoneIphone,
  Work,
} from "@mui/icons-material";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Rating } from "@mui/material";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import { loadUser } from "../../../Redux/Actions/authActions";
// import { GetSingleUser } from "../../../Redux/Actions/EditUserAction";

const SalesProfileLeft = ({ getMainUser, title, name }) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(loadUser());
  //   dispatch(GetSingleUser(user_id));
  // }, [dispatch]);
  //  const { name, title } = useSelector((state) => state.auth);

  // const { getMainUser } = useSelector((s) => s.editUser);
  var KeyVal = title.split(",") || [];
  let admin = KeyVal.find((e) => e === "admin");
  const media = getMainUser?.media;
  /************** */
  return (
    <ProfileData className="left">
      <div className="main-content">
        <div className="image">
          <NavLink to="/profile/main">
            <img src={getMainUser.image_path} alt={title} />
          </NavLink>
        </div>
        <div className="desc">
          <NavLink to="/profile/main">
            <h3>{name}</h3>
          </NavLink>
          <p>{getMainUser.mantras}</p>
        </div>
      </div>
      <div className="inform">
        <span className="rate">
          <Rating name="half-rating-read" defaultValue={4} readOnly />
        </span>
      </div>
      <div className="profileInfo">
        <div className="mainData">
          <div className="items">
            <span className="key">
              <Work className="user" />
            </span>
            <span className="value"> {title}</span>
          </div>
          <div className="items">
            <span className="key">
              <Paid className="money" />
            </span>
            <span className="value"> $ {getMainUser.ppw}</span>
          </div>
          <div className="items">
            <span className="key">
              <PhoneIphone className="phone" />
            </span>
            <span className="value"> {getMainUser.phone}</span>
          </div>
          <div className="items">
            <span className="key">
              <Mail className="mail" />
            </span>
            <span className="value"> {getMainUser.email}</span>
          </div>
          <div className="items">
            <span className="key">
              <LocationOn className="location" />
            </span>
            <span className="value"> {getMainUser.address}</span>
          </div>
          <div className="items">
            <span className="key">
              <Business className="business" />
            </span>
            <span className="value"> {getMainUser.business}</span>
          </div>

          {getMainUser?.document_url !== null ? (
            <>
              <div className="items">
                <span className="key">
                  <PictureAsPdfIcon color="primary" className="business" />
                </span>
                <span className="value">
                  {" "}
                  <a href={getMainUser?.document_url} target="_blank">
                    Sales Agreement
                  </a>{" "}
                </span>
              </div>
            </>
          ) : (
            <>{null}</>
          )}
          <NavLink to="sales-license">
            <div className="items">
              <span className="key">
                <Business className="business" />
              </span>
              <span className="value"> Licenses</span>
            </div>
          </NavLink>
          {media?.document_type === "w9" ? (
            <>
              <div className="items">
                <span className="key">
                  <PictureAsPdfIcon color="primary" className="business" />
                </span>
                <span className="value">
                  {" "}
                  <a href={media?.media_url} target="_blank">
                    W9
                  </a>{" "}
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          <NavLink to="upload-template">
            <div className="items">
              <span className="key">
                <Work className="user" />
              </span>
              <span className="value"> Upload templates</span>
            </div>
          </NavLink>
          <NavLink to="show-template">
            <div className="items">
              <span className="key">
                <Work className="user" />
              </span>
              <span className="value"> Show templates</span>
            </div>
          </NavLink>
          <NavLink to="edit-adders">
            <div className="items">
              <span className="key">
                <SolarPowerIcon className="business" />
              </span>
              <span className="value"> Edit Adders</span>
            </div>
          </NavLink>
        </div>

        <ul>
          {/* <li>
            <span className="key">PPW :</span>
            <span className="value"> $ {getMainUser.ppw}</span>
          </li>
          <li>
            <span className="key">title :</span>
            <span className="value">{title}</span>
          </li>
          <li>
            <span className="key">mobile :</span>
            <span className="value">{getMainUser.phone}</span>
          </li>
          <li>
            <span className="key">email :</span>
            <span className="value">{getMainUser.email}</span>
          </li>
          <li>
            <span className="key">address :</span>
            <span className="value">{getMainUser.address}</span>
          </li>
          <li>
            <span className="key">Business :</span>
            <span className="value">{getMainUser.business}</span>
          </li> */}
          <li>
            <NavLink to="edit">
              <span className="edit">
                <ModeEdit />
                Edit Profile
              </span>
            </NavLink>
            <NavLink to="create-user" className="changePassword">
              Create User
            </NavLink>
            <NavLink to="change-password" className="changePassword">
              Change Password
            </NavLink>
            {admin === "admin" ? (
              <a href="https://admin.boxbyld.tech" className="changePassword">
                {" "}
                Admin Page
              </a>
            ) : (
              <span></span>
            )}
          </li>
        </ul>
      </div>
    </ProfileData>
  );
};

const ProfileData = styled.section`
  background: #fff;
  width: 100%;
  min-height: 93vh;
  box-shadow: 2px 1px 20px 2px rgb(0 0 0 / 16%);
  .main-content {
    text-align: center;
    padding: 1rem 0;
    .image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      margin: 1rem auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .desc {
      color: #888;
      text-transform: uppercase;
      h3 {
        color: #f78787;
        font-size: 2rem;
      }
    }
  }
  .inform {
    text-align: center;
  }

  .profileInfo {
    .mainData {
      padding: 2rem;
      .items {
        /* display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .key {
          font-weight: bold;
          margin-right: 0.3rem;
          color: #003c4c9d;
        }
        .value {
          color: #888;
          font-size: 0.85rem;
        } */
        transition: 0.5s all;
        display: flex;
        align-items: center;
        margin: 0.5rem;
        border-radius: 2rem;
        background: #8888881c;
        backdrop-filter: blur(20px);
        overflow: hidden;
        padding: 0.6rem;
        font-size: 1rem;
        color: #222;
        .key {
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
          /* font-weight: bold; */
          svg {
            color: #fff;
            padding: 0.2rem;
            border-radius: 50%;
            font-size: 1rem;
            width: 30px;
            height: 30px;
            margin-right: 0.5rem;
            &.user {
              background: #d32f2f;
            }
            &.phone {
              background: #003c4c;
            }

            &.money {
              background: #67ac5e;
            }
            &.mail {
              background: #03a9f4;
            }
            &.location {
              background: #ffa000;
            }
            &.business {
              background: #616161;
            }
          }
        }
        .name {
          font-size: 1.1rem;
          color: #ffffff;
        }
      }
      a.active {
        .items {
          background-color: #003c4c;
          color: white;
        }
        color: #41b6db;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 1.5rem 2rem;
      li {
        padding: 0.5rem;
        display: block;
        width: 100%;
        text-transform: capitalize;
        font-size: 0.9rem;
        font-weight: 600;
        color: #777;
        letter-spacing: 2px;

        .key {
          width: 100px;
          display: inline-block;
        }
        .value {
          color: #60757a;
        }
        .edit {
          margin-top: 2rem;
          background-color: #003c4c;
          color: #eee;
          text-align: center;
          border-radius: 1rem;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          box-shadow: 0px 3px 0 2px #00546a;
          &:hover {
            transform: translateY(-1px);
            box-shadow: none;
          }
        }
        .changePassword {
          display: block;
          margin: 2rem 0;
          text-align: center;
          color: #41b6db;
        }
      }
    }
  }
`;

export default SalesProfileLeft;
